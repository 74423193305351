import { Component, OnInit } from '@angular/core';
import { RouteTranslatorService } from '@web/core/services';

@Component({
  selector: 'ala-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(public routeTranslator: RouteTranslatorService) {}

  ngOnInit() {}
}
