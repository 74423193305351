import { Component, Input, OnInit } from '@angular/core';
import { Breadcrumb } from '@common/core/models';
import { RouteTranslatorService } from '@web/core/services';

@Component({
  selector: 'ala-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
})
export class HeroBannerComponent implements OnInit {
  @Input() imageURL: string;
  @Input() title: string;
  @Input() breadcrumbs: Breadcrumb[];

  constructor(public routeTranslator: RouteTranslatorService) {}

  ngOnInit(): void {}
}
