import { SuperModel } from './super-model.model';

export class Image extends SuperModel {
  label?: string;
  url: string;

  constructor(data: any) {
    if (data.Id !== undefined) {
      super({
        id: data.Id,
        label: data.Label,
        url: data.Url,
      });
    } else {
      super(data);
    }
  }
  get serialised() {
    return {};
  }
}
