export enum EApiCallStateNames {
  SEARCH_PAGE = 'SEARCH_PAGE',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  SORTING = 'SORTING',
  FILTERS = 'FILTERS',
}
