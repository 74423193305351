import { Image } from './image.model';
import { Author } from './author.model';
import { SuperModel } from './super-model.model';

export class Book extends SuperModel {
  title: string;
  subtitle?: string;
  type?: string;
  aboutTheBook?: string;
  abstract?: string;
  author?: Author;
  images?: Image[];
  image?: Image;
  flgAntichi?: boolean;

  constructor(data: any) {
    if (data.Id !== undefined) {
      super({
        id: data.Id,
        title: data.Title,
        subtitle: data.Subtitle,
        type: data.Type,
        aboutTheBook: data.AboutTheBook,
        abstract: data.Abstract,
        author: data.Author,
        images: data.Images,
        image: data.Image,
      });
    } else {
      super(data);
    }

    this.title = this.title.trim();

    if (this.author) {
      this.author = new Author(this.author);
    }
    if (this.images) {
      this.images = this.images.map((image: Image) => new Image(image));
    }

    if (this.image) {
      this.image = new Image(this.image);
    }
  }
  get serialised() {
    return {};
  }

  get titleEncoded() {
    return encodeURIComponent(this.title);
  }
}
