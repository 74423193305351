import {
  BitfPPaginationApiRequestPart,
  BitfPSearchApiRequestPart,
} from '@common/libs/bitforce/core/api-call-state/bitf-p-api-call-state';

import { EApiCallStateNames, EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.SEARCH_PAGE,
    apiRequest: {},
    requestParts: [
      new BitfPPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 0, size: 10 },
      }),
      new BitfPSearchApiRequestPart({
        apiRequestPartKey: EApiRequestPartKeys.SEARCH,
        apiRequestPartName: EBitfApiRequestPartsNames.SEARCH,
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
