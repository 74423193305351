import { HttpResponse } from '@angular/common/http';
import { BitfPEnvelopeMapper } from '@bitf/core/parsers/p-parser';
import { bitfGetProp } from '@common/libs/bitforce/utils/bitf-objects.utils';

import {
  IBitfEnvelopeMapperData,
  IBitfApiResponse,
  IBitfApiPPaginationResponse,
  IBitfApiPagination,
} from '@interfaces';

export class AppEnvelopeMapper extends BitfPEnvelopeMapper {
  constructor() {
    super();
  }

  map({ req, event }: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    event = super.map({ req, event });
    const mappedBody: IBitfApiResponse<any> = event.body;
    const originalBody = event.body.originalBody;

    if (originalBody != null) {
      const data = originalBody.Data;
      const hasItems = bitfGetProp(data, 'Items');

      if (hasItems) {
        const items = data.Items;
        const pagination = {
          hasPreviousPage: data.HasPreviousPage,
          hasNextPage: data.HasNextPage,
          paginationInfo: data.PaginationInfo,
          pageIndex: data.PageIndex,
          pageSize: data.PageSize,
          count: data.Count,
          totalCount: data.TotalCount,
          totalPages: data.TotalPages,
        };
        // NOTE: Map items -> content
        mappedBody.content = items;

        mappedBody.pagination = this.mapPagination(pagination);

        // mappedBody.sorting = this.mapSorting(sorting);
      } else if (data) {
        mappedBody.content = data;
      }

      if (originalBody.metadata) {
        mappedBody.metadata = originalBody.metadata;
      }
    }

    return event.clone({
      body: mappedBody,
    });
  }

  mapPagination(pagination: IBitfApiPPaginationResponse): IBitfApiPagination {
    if (!pagination) {
      return;
    }
    const mappedPagination: IBitfApiPagination = {
      first: pagination.hasPreviousPage,
      last: pagination.hasNextPage,
      paginationInfo: pagination.paginationInfo,
      page: pagination.pageIndex,
      size: pagination.pageSize,
      itemsInPage: pagination.count,
      totalItems: pagination.totalCount,
      totalPages: pagination.totalPages,
    };
    return mappedPagination;
  }
}
