import { AppEnvelopeMapper, AppPathBuilder, AppRequestMapper, AppResponseMapper } from '@web/core/parsers';

import { IBitfParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  toastMessage: { durationMs: 5000 },
  parsers: {
    defaultParser: 'appParser',
    parserStrategies: {
      appParser: {
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
};
