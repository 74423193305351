import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('COMMON.META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [
    { code: 'en', label: 'English' },
    { code: 'it', label: 'Italiano' },
  ],
  DEFAULT_LANGUAGE: { code: 'en', label: 'English' },
  LOCAL_STORAGE_VERSION: 1,
  URL_ROUTES_TRANSLATION: {
    contacts: { it: 'contatti', en: 'contacts' },
    museum: { it: 'museo', en: 'museum' },
    artReproduction: { it: 'riproduzioni-darte', en: 'art-reproduction' },
    forSchools: { it: 'per-le-scuole', en: 'for-schools' },
    search: { it: 'cerca', en: 'cerca' },
    centreForStudiesAndResearch: {
      it: 'centro-studi-e-ricerche',
      en: 'centre-for-studies-and-research',
      search: { it: 'ricerca', en: 'search' },
      volumesIndex: {
        it: 'indice-titoli',
        en: 'alphabetical-list-of-online-volumes-in-the-bibliotheca-antiqua',
        detailBook: {
          it: 'dettaglio-libro',
          en: 'detail-book',
        },
        mainBook: {
          it: 'libro-principale',
          en: 'main-book',
        },
      },
      authorsIndex: {
        it: 'indice-autori',
        en: 'alphabetical-list-of-online-authors-in-the-bibliotheca-antiqua',
        detailAuthor: {
          it: 'dettaglio-autore',
          en: 'detail-author',
        },
      },
    },
    abocaExperience: { it: 'aboca-experience', en: 'aboca-experience' },
    thematicVisits: { it: 'Eventi?tipologia=visite+guidate', en: '' },
    labsTreasuresWalks: { it: 'Eventi?tipologia=attivit%C3%A0&tipologia=eventi+fuori+dal+museo', en: '' },
    specialFamilies: { it: 'Eventi?target=famiglie', en: '' },
    specialKids: { it: 'Eventi?target=bambini', en: '' },
    escapeAbocaMuseum: { it: 'escape-aboca-museum', en: 'escape-aboca-museum' },
    sitemap: { it: 'sitemap', en: 'sitemap' },
    infoAndOpenings: { it: 'info-ed-orari', en: 'info-and-opening-hours' },
    privacyPolicy: { it: 'privacy-policy', en: 'privacy-policy' },
  },
};
