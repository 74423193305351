import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { Search } from '@common/core/models';
import { IBitfApiRequest } from '@common/interfaces';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BitfApiService {
  constructor(public injector: Injector) {
    super('search', injector);
  }

  search(request: IBitfApiRequest) {
    return this.post<Search[]>(request);
  }
}
