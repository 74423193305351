import { Book } from './book.model';
import { Image } from './image.model';
import { SuperModel } from './super-model.model';

export class Author extends SuperModel {
  name: string;
  country?: string;
  biography?: string;
  birthDate?: string;
  deathDate?: string;
  books?: Book[];
  image?: Image;
  flgAntichi?: boolean;

  constructor(data: any) {
    if (data.Id !== undefined) {
      super({
        id: data.Id,
        name: data.Name,
        country: data.Country,
        biography: data.Biography,
        birthDate: data.BirthDate,
        deathDate: data.DeathDate,
        books: data.Books,
        image: data.Image,
      });
    } else {
      super(data);
    }

    if (this.image) {
      this.image = new Image(this.image);
    }
    if (this.books) {
      this.books = this.books.map((book: Book) => new Book(book));
    }
  }
  get serialised() {
    return {};
  }

  get title() {
    return `${this.name} ${this.info}`;
  }

  get info() {
    return (
      (this.country?.length ? ` - ${this.country}` : '') +
      (this.birthDate?.length ? ` - ${this.birthDate}` : '') +
      (this.deathDate?.length ? ` - ${this.deathDate}` : '')
    );
  }

  get nameEncoded() {
    return encodeURIComponent(this.name);
  }
}
