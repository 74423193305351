<div class="alphabetical-index-wrapper">
  <div class="alphabetical-index">
    <div class="address-book-index-icon my-3">
      <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
          d="M96 0C60.7 0 32 28.7 32 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H96zM208 288h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H144c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V80zM496 192c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V336z" />
      </svg>
    </div>
    <a *ngFor="let item of items | keyvalue"
      [ngClass]="{'disabled': item.value.length === 0}"
      routerLink="."
      [fragment]="item.key">
      {{item.key | uppercase}}
    </a>
  </div>
</div>