<div class="d-flex flex-column w-100">
  <div class="alphabetical-list-title">
    {{ title }}
  </div>
  <ng-container *ngFor="let item of items | keyvalue">
    <div class="anchor-container"
      *ngIf="item.value.length > 0"
      [id]="item.key">
      <div class="alphabetical-index-header">
        {{item.key | uppercase}}
      </div>
    </div>
    <div *ngFor="let data of item.value"
      class="d-flex w-100 mb-3 alphabetical-list"
      [ngClass]="{'align-items-center': itemType === 'author'}">
      <ng-container *ngIf="itemType === 'book'">
        <ng-container *ngTemplateOutlet="book; context: {data: data}"></ng-container>
      </ng-container>
      <ng-container *ngIf="itemType === 'author'">
        <ng-container *ngTemplateOutlet="author; context: {data: data}"></ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #book
    let-book="data">
    <div class="icon-bg book-icon mr-3">
    </div>
    <div class="d-flex flex-1 flex-column">
      <a [routerLink]="routeTranslator.translateBARoute(['centreForStudiesAndResearch', 'volumesIndex', 'detailBook'], '/'+book.id+'/'+book.titleEncoded)"
        [queryParams]="{flgAntichi: book.flgAntichi}">
        {{book.title}}
      </a>
      <a [routerLink]="routeTranslator.translateBARoute(['centreForStudiesAndResearch', 'authorsIndex', 'detailAuthor'], '/'+book.author.id+'/'+book.author.nameEncoded)"
        [queryParams]="{flgAntichi: book.author.flgAntichi}">
        {{book.author.name}}
      </a>
      <div class="item-separator mt-3"></div>
    </div>
  </ng-template>

  <ng-template #author
    let-author="data">
    <div class="icon-bg pencil-icon mr-3">
    </div>
    <div class="flex-1">
      <span>
        <a [routerLink]="routeTranslator.translateBARoute(['centreForStudiesAndResearch', 'authorsIndex', 'detailAuthor'], '/'+author.id+'/'+author.nameEncoded)"
          [queryParams]="{flgAntichi: author.flgAntichi}">
          {{author.name}}
        </a>
        <span class="font-italic">
          {{author.info}}
        </span>
      </span>
    </div>
  </ng-template>
</div>