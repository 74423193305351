import { Injectable } from '@angular/core';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';
import { CONSTANTS } from '@common/constants/common.constants';

@Injectable({
  providedIn: 'root',
})
export class RouteTranslatorService {
  constructor(private bitfDynamicLocaleService: BitfDynamicLocaleService) {}

  translateBARoute(routes: string[] = [], params = ''): string[] {
    const url = this.translateRoute('', routes, params).split('/');
    url.unshift('/');
    return url;
  }

  translateAMRoute(routes: string[] = [], params = ''): string {
    return this.translateRoute('https://www.abocamuseum.it/', routes, params);
  }

  private translateRoute(prefix: string = '', routes: string[] = [], params = ''): string {
    const langCode = this.bitfDynamicLocaleService.locale?.code || 'en';
    let route = `${prefix}${langCode}`;
    let routeHelper = CONSTANTS.URL_ROUTES_TRANSLATION;
    routes.forEach((routePart, index) => {
      if (routeHelper[routePart]) {
        route += `/${routeHelper[routePart][langCode]}`;
        routeHelper = routeHelper[routePart];
      }
    });
    return route + params;
  }
}
