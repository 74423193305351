<div class="hero-banner"
  style="background-image: url('{{imageURL}}');">

  <div class="w-100 position-absolute text-block">
    <div class="container">
      <h1>{{title}}</h1>

      <hr class="mb-xlg">

      <div class="d-flex align-items-center flex-wrap">
        <a class="breadcrumb-el"
          [href]="routeTranslator.translateAMRoute()">
          Homepage
        </a>
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let isLast = last">
          <div class="breadcrumb-arrow"></div>
          <span *ngIf="isLast"
            class="breadcrumb-el font-italic">{{breadcrumb.label | translate}}</span>
          <a *ngIf="!isLast"
            class="breadcrumb-el"
            [routerLink]="routeTranslator.translateBARoute(breadcrumb.routes)">
            {{breadcrumb.label | translate}}
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>