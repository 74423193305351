import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';

import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfTouchEventDirective } from '@common/libs/bitforce/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BitfMatLoaderComponent } from '@bitf/core/components/ui/loader/material/bitf-mat-loader/bitf-mat-loader.component';
import { BitfImageComponent } from '@common/libs/bitforce/core/components/ui/image/bitf-image/bitf-image.component';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DateAdapterService } from '@common/core/services';
import { BitfMatLanguageSelectorComponent } from '@common/libs/bitforce/core/components/ui/language-selector/material/bitf-mat-language-selector.component';
import { AlphabeticalListComponent } from './components/alphabetical-list/alphabetical-list.component';
import { AlphabeticalIndexComponent } from './components/alphabetical-index/alphabetical-index.component';
import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { ItemDetailComponent } from './components/item-detail/item-detail.component';
import { ItemGalleryComponent } from './components/item-gallery/item-gallery.component';
import { BitfMatSearchComponent } from '@common/libs/bitforce/core/components/ui/search/material/bitf-mat-search.component';

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  BitfMatPaginatorComponent,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  BitfMatOkCancelDialogComponent,
  BitfMatLoaderComponent,
  BitfImageComponent,
  BitfMatLanguageSelectorComponent,
  BitfMatSearchComponent,
  AlphabeticalListComponent,
  AlphabeticalIndexComponent,
  HeroBannerComponent,
  ItemDetailComponent,
  ItemGalleryComponent,
];
const SHARED_DIRECTIVES = [];
const PROVIDERS = [DatePipe, { provide: DateAdapter, useClass: DateAdapterService }];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
