<div class="item-detail">
  <div class="left-block">
    <h2>{{title}}</h2>
    <h4 [hidden]="!subtitle"
      class="book-subtitle">{{subtitle}}</h4>
    <h4 [hidden]="!bookType"
      class="book-type">{{bookType}}</h4>

    <hr class="mb-xlg my-5">

    <img [src]="image?.url"
      *ngIf="image?.url"
      class="img-thumbnail">
    <p class="description"
      *ngIf="description"
      [innerHTML]="description"></p>

    <div *ngIf="images?.length">
      <!-- <hr class="mb-xlg my-5"> -->
      <ala-item-gallery [title]="title"
        [images]="images"></ala-item-gallery>
    </div>

  </div>

  <div class="right-block">
    <div class="links-container">
      <div class="works"
        *ngIf="books">
        <h4 class="works-title">{{ 'COMMON.LABEL.WORKS' | translate}}</h4>
        <div>
          <a [routerLink]="getBookUrl(book)"
            class="d-flex align-items-center book-link"
            *ngFor="let book of books">
            <div class="book-icon"></div>
            <span class="flex-1">{{book.title}}</span>
          </a>
        </div>
      </div>

      <div *ngIf="aboutTheBook">
        <h5 class="about-title">{{ 'COMMON.LABEL.ABOUT_THE_BOOK' | translate}}</h5>
        <div class="about-text"
          [innerHtml]="aboutTheBook"></div>
      </div>

      <hr *ngIf="books || aboutTheBook"
        class="mb-xlg">

      <div class="links">
        <a [routerLink]="routeTranslator.translateBARoute(['centreForStudiesAndResearch', 'search'])"
          class="d-flex align-items-center link">
          <div class="icon-bg search-icon"></div>
          <span>{{ 'COMMON.LABEL.SEARCH_BOTANIC_IMAGES' | translate}}</span>
        </a>
        <a [routerLink]="routeTranslator.translateBARoute(['centreForStudiesAndResearch', 'authorsIndex'])"
          class="d-flex align-items-center link">
          <div class="icon-bg pencil-icon"></div>
          <span>{{ 'COMMON.LABEL.AUTHORS_LIST' | translate}}</span>
        </a>
        <a [routerLink]="routeTranslator.translateBARoute(['centreForStudiesAndResearch', 'volumesIndex'])"
          class="d-flex align-items-center link mb-0">
          <div class="icon-bg book-icon"></div>
          <span>{{ 'COMMON.LABEL.BOOKS_LIST' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>