import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Author, Book } from '@common/core/models';

@Component({
  selector: 'ala-alphabetical-index',
  templateUrl: './alphabetical-index.component.html',
  styleUrls: ['./alphabetical-index.component.scss'],
})
export class AlphabeticalIndexComponent implements OnInit {
  @Input() items: { [key: string]: Array<Book | Author> };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.fragment.subscribe(fragment => {
      if (fragment) {
        document.querySelector('#' + fragment)?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    });
  }
}
