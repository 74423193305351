<button mat-button
  [matMenuTriggerFor]="languageMenu"
  class="d-flex align-items-center">
  <span *ngIf="useShortLabel">
    {{bitfDynamicLocaleService?.locale.code | uppercase}}
  </span>
  <span *ngIf="!useShortLabel">
    {{bitfDynamicLocaleService?.locale.label}}
  </span>
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #languageMenu="matMenu">
  <button mat-menu-item
    (click)="changeLocale(supportedLanguage)"
    *ngFor="let supportedLanguage of supportedLanguages">
    {{supportedLanguage.label}}
  </button>
</mat-menu>