<div class="not-found">
  {{ 'COMMON.LABEL.NOT_FOUND' | translate}}
  <a class="text-decoration-none"
    [routerLink]="routeTranslator.translateBARoute(['bibliothecaAntiqua'])">
    <button mat-flat-button
      class="mt-3 font-weight-bold text-uppercase"
      color="primary">
      {{'COMMON.LABEL.BACK_TO_HOME' | translate}}
    </button>
  </a>
</div>