import { Component, Input, OnInit } from '@angular/core';
import { Breakpoints } from '@angular/cdk/layout';
import { StoreService } from '@common/core/services';
import { eStoreActions } from '@web/enums';
import { IBitfStoreEvent } from '@common/interfaces';
import { Store } from '@common/core/models';

export enum EBreakpoint {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

@Component({
  selector: 'bitf-image',
  templateUrl: './bitf-image.component.html',
  styleUrls: ['./bitf-image.component.scss'],
})
export class BitfImageComponent implements OnInit {
  @Input() nativeLazy: boolean;
  @Input() layout: 'responsive' | 'fixed' = 'responsive';
  @Input() defaultBreakpoint = 'xs';
  @Input() sources: {
    [x: string]: { '1x': string; '2x'?: string; aspectRatio?: string; width?: string; height?: string };
  };

  // Img props
  loading = 'eager';
  src = '';
  srcset = '';
  sizes = '';
  imgStyle = {};

  private breakpoints = { xs: 1, sm: 600, md: 960, lg: 1280, xl: 1920 };

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {
    if (this.nativeLazy) {
      this.loading = 'lazy';
    }

    // Set srcset
    Object.keys(this.breakpoints).forEach((breakpoint, index) => {
      if (this.layout === 'responsive') {
        this.setSrcsetAsResponsive(breakpoint);
      } else if (this.layout === 'fixed') {
        this.setSrcsetAsFixed(breakpoint);
      }
    });

    // Set sizes
    Object.values(this.breakpoints)
      .reverse()
      .forEach(breakpoint => {
        const size = '(min-width: ' + breakpoint + 'px' + ') ' + breakpoint + 'px, ';
        this.sizes += size;
      });

    this.sizes += this.breakpoints[this.defaultBreakpoint] + 'px';

    this.updateImgStyle(this.storeService.store);
    this.storeService.selectStore(eStoreActions.BREAKPOINT).subscribe((event: IBitfStoreEvent<Store>) => {
      this.updateImgStyle(event.store);
    });
  }

  private updateImgStyle(store: Store) {
    let currentBreakpoint = 'xs';
    if (this.layout === 'fixed') {
      currentBreakpoint = 'xs';
    } else {
      currentBreakpoint = store.activeBreakpoints.isXSmall
        ? 'xs'
        : store.activeBreakpoints.isSmall
        ? 'sm'
        : store.activeBreakpoints.isMedium
        ? 'md'
        : store.activeBreakpoints.isLarge
        ? 'lg'
        : 'xl';
    }

    let source = this.sources[currentBreakpoint];
    if (!source) {
      source = this.sources[Object.keys(this.sources).reverse()[0]];
    }
    const width = source?.width || '100%';
    const height = source?.height || '100%';

    if (source?.aspectRatio) {
      this.imgStyle = { width, height, aspectRatio: source?.aspectRatio };
    } else {
      this.imgStyle = { width, height };
    }
  }

  // Responsive srcset
  private setSrcsetAsResponsive(breakpoint: string) {
    if (this.sources[breakpoint]) {
      const imageUrl = this.sources[breakpoint]['2x']
        ? this.sources[breakpoint]['2x']
        : this.sources[breakpoint]['1x'];
      const imageWidth = this.breakpoints[breakpoint] + 'w';
      const setItem = imageUrl + ' ' + imageWidth + ', ';
      this.srcset += setItem;

      if (!this.src) {
        this.src = imageUrl;
      }
    }
  }

  // Fixed srcset
  private setSrcsetAsFixed(breakpoint: string) {
    if (this.sources[breakpoint]) {
      if (this.sources[breakpoint]['1x']) {
        const image1xUrl = this.sources[breakpoint]['1x'];
        const set1xItem = image1xUrl + ' 1x' + ', ';
        this.srcset += set1xItem;

        if (!this.src) {
          this.src = image1xUrl;
        }
      }

      if (this.sources[breakpoint]['2x']) {
        const image2xUrl = this.sources[breakpoint]['2x'];
        const set2xItem = image2xUrl + ' 2x' + ', ';
        this.srcset += set2xItem;
      }
    }
  }
}
