import { Component, Input, OnInit } from '@angular/core';
import { Author, Book } from '@common/core/models';
import { RouteTranslatorService } from '@web/core/services';

@Component({
  selector: 'ala-alphabetical-list',
  templateUrl: './alphabetical-list.component.html',
  styleUrls: ['./alphabetical-list.component.scss'],
})
export class AlphabeticalListComponent implements OnInit {
  @Input() items: { [key: string]: Array<Book | Author> };
  @Input() itemType: 'book' | 'author';
  @Input() title: string;

  constructor(public routeTranslator: RouteTranslatorService) {}

  ngOnInit(): void {}
}
