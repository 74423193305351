import { Component } from '@angular/core';

import { AppSessionService } from '@services';
import { environment } from '@env/environment';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';
import { CONSTANTS } from './constants';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ala-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private appSessionService: AppSessionService,
    private bitfDynamicLocaleService: BitfDynamicLocaleService,
    private router: Router
  ) {
    this.appSessionService.init().then(() => {
      this.updateLocale();
      router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: RouterEvent) => {
        this.updateLocale();
        if (!event.url.includes('#')) {
          // if the url does not contain a fragment scroll to top
          document.querySelector('.mat-sidenav-content').scrollTop = 0;
        }
      });
    });
  }

  private updateLocale() {
    if (location.pathname === '/') {
      this.router.navigate([`/${this.bitfDynamicLocaleService.locale.code}`]);
    } else if (location.pathname.includes('/it')) {
      this.bitfDynamicLocaleService.setLocale(CONSTANTS.SUPPORTED_LANGUAGES[1]);
    } else if (location.pathname.includes('/en')) {
      this.bitfDynamicLocaleService.setLocale(CONSTANTS.SUPPORTED_LANGUAGES[0]);
    }
  }
}
