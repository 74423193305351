import { SuperModel } from './super-model.model';
import { Book } from './book.model';
import { Image } from './image.model';

export class SearchResult extends SuperModel {
  table: string;
  publication: string;
  book: Book;
  image: Image;

  constructor(data: any) {
    if (data.Id !== undefined) {
      super({
        id: data.Id,
        table: data.Table,
        publication: data.Publication,
        book: data.Book,
        image: data.Image,
      });
    } else {
      super(data);
    }

    if (this.book) {
      this.book = new Book(this.book);
    }
    if (this.image) {
      this.image = new Image(this.image);
    }
  }
  get serialised() {
    return {};
  }
}
