<div class="item-gallery" #itemGallery>
  <h6 *ngIf="isMainBook"
    class="font-weight-bold text-uppercase font-style-normal">{{'BOOKS.MAIN_BOOK_TITLE' | translate}}</h6>
  <h6 *ngIf="!isMainBook">{{'BOOKS.GALLERY_TITLE' | translate}}</h6>

  <div class="img-container">

    <div class="breadcrumb-arrow left"
      [ngClass]="{'disabled': !canScrollLeft}"
      (click)="scrollLeft()"></div>

    <div class="d-flex img-slider" #slider>
      <div *ngFor="let img of images; let i = index;"
        class="img-preview"
        (click)="zoomImage(i)">
        <img [src]="img?.url"
          loading="lazy" />
      </div>
    </div>

    <div class="breadcrumb-arrow right"
      [ngClass]="{'disabled': !canScrollRight}"
      (click)="scrollRight()"></div>
  </div>

  <hr class="mb-xlg my-5">
</div>

<div [hidden]="!isZoomView"
  (click)="closeZoomView()"
  class="zoom-view">

  <div class="breadcrumb-arrow left"
    (click)="prevImage($event)"></div>

  <div class="img-container">
    <figure (click)="nextImage($event)">
      <img [src]="images[selectedImage]?.url"
        loading="lazy" />
      <figcaption>
        <div class="title">{{title}}</div>
        <div class="counter">{{selectedImage + 1}} {{'COMMON.LABEL.OF' | translate}} {{images.length}}</div>
      </figcaption>
    </figure>
  </div>

  <div class="breadcrumb-arrow right"
    (click)="nextImage($event)"></div>

</div>