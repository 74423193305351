import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Image } from '@common/core/models';
import { RouteTranslatorService } from '@web/core/services';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ala-item-gallery',
  templateUrl: './item-gallery.component.html',
  styleUrls: ['./item-gallery.component.scss'],
})
export class ItemGalleryComponent implements OnInit {
  @ViewChild('slider', { static: true }) slider: ElementRef;
  @ViewChild('itemGallery', { static: true }) itemGallery: ElementRef;

  @Input() images: Image[];
  @Input() title: string;
  isMainBook = false;
  maxScroll = 0;
  scrollIndex = 0;
  canScrollLeft = false;
  canScrollRight = true;

  isZoomView = false;
  selectedImage = 0;

  constructor(private route: ActivatedRoute, public routeTranslator: RouteTranslatorService) {
    this.route.data.pipe(take(1)).subscribe(data => {
      if (data.mainBook) {
        this.isMainBook = true;
      }
    });
  }

  ngOnInit(): void {
    this.scrollInit();
    if (!this.images?.length) {
      this.itemGallery.nativeElement.hidden = true;
    }
  }

  zoomImage(i) {
    this.selectedImage = i;
    this.isZoomView = true;
  }

  closeZoomView() {
    this.isZoomView = false;
  }

  prevImage(event: Event) {
    event.stopPropagation();
    this.selectedImage = this.selectedImage === 0 ? this.images.length - 1 : this.selectedImage - 1;
  }

  nextImage(event: Event) {
    event.stopPropagation();
    this.selectedImage = (this.selectedImage + 1) % this.images.length;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.scrollIndex = 0;
    this.scrollInit();
    this.updateScroll();
  }

  scrollInit() {
    if (!this.slider) {
      return;
    }
    const img_width = 116;
    this.maxScroll = img_width * this.images.length - this.slider.nativeElement.parentElement.clientWidth;
    this.canScrollRight = this.maxScroll > 0;
  }

  scrollLeft() {
    if (this.canScrollLeft) {
      this.scrollIndex--;
      this.updateScroll();
    }
  }

  scrollRight() {
    if (this.canScrollRight) {
      this.scrollIndex++;
      this.updateScroll();
    }
  }

  private updateScroll() {
    this.slider.nativeElement.style.transform = `translate3d(-${200 * this.scrollIndex}px, 0px, 0px)`;
    this.canScrollLeft = this.scrollIndex > 0;
    this.canScrollRight = 200 * this.scrollIndex < this.maxScroll;
  }
}
