import { SuperModel } from './super-model.model';

export class Search extends SuperModel {
  label: string;
  flgAntichi?: boolean;

  constructor(data: any) {
    if (data.Id !== undefined) {
      super({
        id: data.Id,
        label: data.Label,
      });
    } else {
      super(data);
    }
  }
  get serialised() {
    return {};
  }
}
