import { Component, Input, OnInit } from '@angular/core';
import { Book, Image } from '@common/core/models';
import { RouteTranslatorService } from '@web/core/services';

@Component({
  selector: 'ala-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
})
export class ItemDetailComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() description: string;
  @Input() image: Image;
  @Input() images: Image[];

  @Input() books: Book[];
  @Input() aboutTheBook: string;
  @Input() bookType: string;

  constructor(public routeTranslator: RouteTranslatorService) {}

  ngOnInit(): void {}

  getBookUrl(book: Book) {
    return this.routeTranslator.translateBARoute(
      ['centreForStudiesAndResearch', 'volumesIndex', 'detailBook'],
      `/${book.id}/${book.titleEncoded}`
    );
  }
}
