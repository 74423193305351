import { Injectable } from '@angular/core';

import { BitfStorageService } from '@bitf/services/storage/bitf-storage.service';

import { Storage } from '@models';

@Injectable({
  providedIn: 'root',
})
// NOTE: inital data must be an object literal to be passed in the storeClass constructor not a storeClass
// instance this is to avoid to create something like new storeClass(justClone(StoreClassInstance));
// which will lead to problems
export class StorageService extends BitfStorageService<Storage> {
  constructor() {
    // super({
    //   nativeStorage: localStorage,
    //   storageKey: 'storage',
    //   storageClass: Storage,
    //   initialData: { prop1: 'Initial storage value' },
    // });
    super({
      storageClass: Storage,
      initialData: {},
    });
  }
}
